import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    primary: {
      400: '#008739',
      500: '#008739',
      600: '#027432',
      700: '#027432',
    },
    gray: {
      50: '#F4F4F7',
      100: '#F0F0F0',
      150: '#DCDDE3',
      200: '#C6C8D1',
      300: '#B0B3C0',
      400: '#A0A4B3',
      500: '#7C8096',
      600: '#646981',
      700: '#4D5163',
      800: '#363843',
      900: '#0F2233',
    },
    blue: {
      50: '#EAF6FF',
      500: '#0D71F0',
      600: '#0A5AC0',
      800: '#052D5F',
    },
    orange: {
      500: '#FF9D2E',
    },
    green: {
      400: '#008739',
      500: '#027432',
      600: '#005423',
      700: '#027432',
    },
  },
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
  },
})